import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonStarted from '../buttonStarted/buttonStarted';
import styles from './styles.module.css';

export default function CallToAction({ clickEvent, text }) {
  return (
    <Container className={styles.ctaContainer}>
      <Row className={styles.ctaHeaderRow}>
        <Col className={styles.ctaHeaderContent} md={12} lg={9}>
          {text.map((t, index) =>
            <div key={index}>{t}</div>
          )}
        </Col>
        <Col className={styles.ctaHeaderButton} md={12} lg={3}>
          <ButtonStarted clickEvent={clickEvent} />
        </Col>
      </Row>
    </Container>
  )
}
