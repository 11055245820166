import { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../../components/header/header';
import Dictionaries from '../../components/dictionaries/dictionaries';
import Steps from '../../components/steps/steps';
import Questions from '../../components/questions/questions';
import SignUp from '../../components/signUp/signUp';
import Footer from '../../components/footer/footer';
import ButtonStarted from '../../components/buttonStarted/buttonStarted';
import SignUpForm from '../../components/signUp/signUpForm/signUpForm';
import ModalSignUpSuccess from '../../modals/signUpSuccess/signUpSuccess';
import styles from './styles.module.css';

window.onbeforeunload = function () { window.scrollTo(0, 0) }

export default function Registration() {
  const signUpRef = useRef(null);
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const content = {
    headerText: {
      title: "Unlock total wellness through sleep",
      text: "Claim your FREE Oximeter*, start using the Wellness Hub App BETA, and shape the future of health technology!",
      subtext: "*This device is for General Wellness use only and not intended for medical use."
    },
    questionsText: {
      title: "Some questions you might want to ask us right now",
      text: "Everything you need to know about sleep health and your journey. Lean on our experts for the answers.",
      link: "Stay connected and discover.",
      questions:
      [
        {
          "id": 0,
          "header": "What is the purpose of signing up for this program?",
          "body": "Signing up for this program allows you to play a vital role in providing feedback on future health products. You will be asked to contribute your health and lifestyle insights including, details about you (name, age, gender, and OSA diagnosis status) as well as feedback of your experience using the app.  You will also use an O2 ring to record nightly oxygen levels. This will be provided free of charge."
        },
        {
          "id": 1,
          "header": "How do I receive the O2 ring for the program?",
          "body": "Once you sign up and become a part of our pilot, you'll receive your O2 ring. This device is a part of the program experience, and we ask you to use it and the Wellness Hub App for a period of four weeks."
        },
        {
          "id": 2,
          "header": "What should I expect when recording my SpO2 and mood?",
          "body": "When you record how you feel, and how you felt you slept, monitoring your SpO2 can be an additional metric to help you understand what impacts your wellness."
        },
        {
          "id": 3,
          "header": "Can I opt-out of the program at any time?",
          "body": <>Yes, you have the flexibility to <a href='https://resmed1.qualtrics.com/jfe/form/SV_4TVnmhhBRngRIP4' target="_blank" rel="noreferrer">opt-out</a> of the program at any time. We value your participation but understand that circumstances may change. Please follow the <a href='https://resmed1.qualtrics.com/jfe/form/SV_4TVnmhhBRngRIP4' target="_blank" rel="noreferrer">opt-out</a> and complete the form, once opted out of the program, we will remove all your identifiable data and access to the WellnessHub app we cease.</>
        },
        {
          "id": 4,
          "header": "How often will I be asked to share feedback during the pilot program?",
          "body": "The frequency of feedback requests may vary. You will receive a feedback request at the end of the program, as well as during the program if you experience any difficulties. We aim to keep all feedback requests considerate of your time. You'll receive communication about when your input is needed, ensuring that your participation remains valuable and convenient for you."
        },
        {
          "id": 5,
          "header": "How will my personal data be managed as a part of the program?",
          "body": <>Your identity and any personal data you voluntarily provide to us for your participation in this program will be protected in secure systems, and kept confidential and only accessible by our team. During the course of the program, we may store and access your personal data outside of Australia, including the UK, Singapore and the US. Management of your personal data will be subject to applicable laws. For more information on how we will manage your data, please refer to our <a href='/privacy-policy' target="_blank" rel="noreferrer">Privacy Policy</a> as it relates to 'Research Participants'.</>
        }
      ]
    },
    formText: {
      title: "Begin your journey to learning more about you",
      text: "Sign-up now and claim Your FREE Oximeter and Shape the Future of Health Tech by Embracing the Power of Restful Nights!"
    }
  }

  const goToSignUp = () => {
    signUpRef.current.scrollIntoView()
  }

  const handleClose = () => {
    setShow(false);
    window.location.reload(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div className="App">
      <Header text={content.headerText}>
        <Row className={styles.buttonRow}>
          <Col className={styles.buttonColumn}>
            <ButtonStarted clickEvent={goToSignUp} />
          </Col>
        </Row>
      </Header>
      <Steps />
      <Dictionaries clickEvent={goToSignUp} />
      <Questions clickEvent={goToSignUp} questions={content.questionsText} />
      <SignUp signUpRef={signUpRef} text={content.formText}>
        <SignUpForm setName={setName} handleShow={handleShow} />
      </SignUp>
      <Footer includeRefs />
      <ModalSignUpSuccess show={show} name={name} handleClose={handleClose} />
    </div>
  );
}
