import Article from '../../components/article/article';
import styles from './styles.module.css';

export default function Privacy() {
  const title = ["Personal Information Collection Notice", "Issue date: [March 12, 2024]"]

  return (
    <div className="App">
      <Article title={title}>
        <div>ResMed Ltd, including but not limited to any related body corporate from time to time, (individually and together referred to as “ResMed”) are referred to as “we”, “our” or “us” in this notice.</div>
        <div>ResMed collects personal information about you through the Wellness Hub. This notice forms part of our Privacy Policy, and together they form our notice for collecting personal information about you through the Wellness Hub. You can obtain a copy of our Privacy Policy directly from our website (https://www.resmed.com.au/privacy-policy) or by contacting our Privacy Officer using the details set out below.</div>
        <div>&nbsp;</div>
        <div>1. Who is collecting your personal information?</div>
        <div>Your personal information is being collected by ResMed. Our Privacy Officer handles enquiries and requests related to our handling of personal information and can be contacted if you have any questions about our management of personal information or compliance with applicable data protection laws.</div>
        <div>In writing:</div>
        <div>
          <div>Privacy Officer</div>
          <div>ResMed</div>
          <div>1 Elizabeth Macarthur Drive</div>
          <div>Bella Vista NSW 2153</div>
        </div>
        <div>By email: privacy.apac@resmed.com</div>
        <div>&nbsp;</div>
        <div>2. Collection of your personal information</div>
        <div>We will collect your personal information from you directly whenever it is reasonable and practical to do so. There are several ways we may collect your personal information, including when you:</div>
        <ul>
          <li>Submit completed registration forms either through our website, by mail, email or by hand</li>
          <li>Deal with us face-to-face, in writing or by telephone</li>
          <li>Participate in any of our promotions or subscribe to any of our offers or publications</li>
          <li>Submit any request to us</li>
          <li>Visit our website</li>
          <li>In the course of supplying products and services to you or through our other dealings with you</li>
        </ul>
        <div>Where we use third-party service providers, these parties may also collect personal information from you on our behalf. In some cases, we may also collect your personal information from publicly available records, our related bodies corporate, or non-related third parties.</div>
        <div>&nbsp;</div>
        <div>3. The purpose of collecting your personal information</div>
        <div>We collect, use and disclose personal information as reasonably necessary for our business purposes which include:</div>
        <ul>
          <li>To respond to your requests for information or materials</li>
          <li>To communicate with you about the Wellness Hub, for example, to provide you with administrative communications regarding your participation in related programs, your membership, newsletters, press releases, or advertisements relating to products, offers, or programs that may be of interest to you</li>
          <li>To conduct internal analyses such as surveys and market research</li>
          <li>To administer your access of the Wellness Hub App</li>
        </ul>
        <div>&nbsp;</div>
        <div>4. What may happen if we do not collect your personal information?</div>
        <div>If we do not collect your personal information we will not be able to process your registration or administer your access to  Wellness Hub App, including participation in any related programs.</div>
        <div>&nbsp;</div>
        <div>5. Who will we disclose your personal information to?</div>
        <div>We may disclose your personal information to our related bodies corporate and third parties for the purposes set out above. The third parties to whom we may disclose your personal information include our trusted third party service providers, engaged to help us run the Wellness Hub and its programs.</div>
        <div>&nbsp;</div>
        <div>6. Overseas disclosures of your personal information</div>
        <div>Sometimes the servers on which ResMed stores personal information are located overseas, including in the United Kingdom, Japan and the United States of America. In those cases, ResMed takes all reasonable steps to make arrangements that provide for privacy protection of personal information to a standard in accordance with our Privacy Policy and applicable data protection laws.</div>
        <div>&nbsp;</div>
        <div>7. Access to and correction of your personal information</div>
        <div>Our <a href="https://www.resmed.com.au/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> contains information about how you can access the personal information we hold about you and seek correction of that information.</div>
        <div>&nbsp;</div>
        <div>8. Privacy complaints</div>
        <div>Our <a href="https://www.resmed.com.au/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> contains information about how you may make a complaint about a breach of your privacy.</div>
      </Article>
    </div >
  )
}
