import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './styles.module.css';
import Step from './step/step';

function Steps() {
  const steps = [
    {
      "id": 1,
      "header": "Sign-up today",
      "body": "Start your health exploration — Sign up now to be a valued participant in this program!"
    },
    {
      "id": 2,
      "header": "Receive your Oximeter",
      "body": "Get equipped to contribute — Receive your FREE Oximeter and download the companion Wellness Hub app."
    },
    {
      "id": 3,
      "header": "Record your oxygen and wellness",
      "body": "Begin participating — Document oxygen levels, your sleep and wellness in the Wellness Hub app for 4 weeks."
    },
    {
      "id": 4,
      "header": "Share your feedback",
      "body": "Share your thoughts and help influence future health products!"
    }
  ]

  return (
    <div className={styles.stepsWrapper}>
      <Container className={styles.stepsContainer}>
        <Row className={styles.headerRow}>
          <Col className={styles.headerColumn}>
            What to expect
          </Col>
        </Row>
        <Row className={styles.stepsRow}>
          {steps.map((s) =>
            <Col key={s.id} sm={12} md={6} lg={3}>
              <Step data={s}/>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <video className={styles.video} controls>
              <source src="videos/WellnessHub.mp4" type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
    </div>

  );
}

export default Steps;