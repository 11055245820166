import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';

function Header({ text, children }) {
  return (
    <div className={styles.headerWrapper}>
      <Container className={styles.headerContainer}>
        <Row className={styles.logoRow}>
          <Col className={styles.logoColumn} md={4} lg={3} xl={2}>
            <Image className={styles.logoImage} src="images/resmedLogo.png" fluid />
          </Col>
        </Row>
        <Row className={styles.contentRow}>
          <Col className={styles.contentColumnLeft} md={12} lg={6}>
            <Stack gap={3}>
              {text?.title && <div className={styles.contentTitle}>{text.title}</div>}
              {text?.text && <div className={styles.contentText}>{text.text}</div>}
              {text?.subtext && <div className={styles.contentSubtext}>{text.subtext}</div>}
            </Stack>
          </Col>
          <Col className={styles.contentColumnRight} md={12} lg={6}>
            <Image className={styles.contentImage} src="images/headerImage.png" fluid />
          </Col>
        </Row>
        {children}
      </Container>
    </div>
  );
}

export default Header;