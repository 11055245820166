import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Registration from './pages/Registration/Registration';
import Privacy from './pages/Privacy/Privacy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import CollectionNotice from './pages/CollectionNotice/CollectionNotice';
import Support from './pages/Support/Support';
import { useEffect } from 'react';

function App() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Wellness Hub Support';
        break;
      case '/privacy-policy':
        document.title = 'Wellness Hub Privacy Policy';
        break;
      case '/terms-of-use':
        document.title = 'Wellness Hub Terms of Use';
        break;
      case '/sign-up-form':
        document.title = 'Wellness Hub Sign Up';
        break;
      case '/collection-notice':
        document.title = 'Wellness Hub Collection Notice';
        break;
      default:
        document.title = 'Wellness Hub Support';
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route exact path="/" Component={Support} />
      <Route exact path="/sign-up-form" Component={Registration} />
      <Route exact path="/privacy-policy" Component={Privacy} />
      <Route exact path="/terms-of-use" Component={TermsOfUse} />
      <Route exact path="/collection-notice" Component={CollectionNotice} />
    </Routes>
  );
}

function Root() {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default Root;