import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import toast, { toastConfig } from 'react-simple-toasts';
import styles from './styles.module.css';
import 'react-simple-toasts/dist/theme/dark-edge.css';
import './form.css';

export default function SignUpForm({ setName, handleShow }) {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandCheck, setExpandCheck] = useState(false);
  const [attemptSubmit, setAttemptSubmit] = useState(false);

  const [formAge, setFormAge] = useState(null);
  const [formGender, setFormGender] = useState(null);
  const [formDevice, setFormDevice] = useState(null);
  const [formDiagnosis, setFormDiagnosis] = useState(null);
  const [formStore, setFormStore] = useState(null);

  const buttonsAge = [
    {
      text: '18-28',
      value: '18-28'
    },
    {
      text: '29-38',
      value: '29-38'
    },
    {
      text: '39-48',
      value: '39-48'
    },
    {
      text: '49-58',
      value: '49-58'
    },
    {
      text: '59-68',
      value: '59-68'
    },
    {
      text: '69-78',
      value: '69-78'
    },
    {
      text: '79+',
      value: '79+'
    }
  ]
  const buttonsGender = [
    {
      text: 'Male',
      value: 'Male'
    },
    {
      text: 'Female',
      value: 'Female'
    },
    {
      text: 'Prefer Not To Say',
      value: 'N/A'
    }
  ]
  const buttonsDevice = [
    {
      text: 'Snoring Mouthguard',
      value: 'MRD'
    },
    {
      text: 'CPAP Machine',
      value: 'CPAP'
    }
  ]
  const buttonsDiagnosis = [
    {
      text: 'Less than a week',
      value: '<1w'
    },
    {
      text: '1-2 weeks',
      value: '1-2w'
    },
    {
      text: '2-4 weeks',
      value: '2-4w'
    },
    {
      text: '1-2 months',
      value: '1-2m'
    },
    {
      text: '2-4 months',
      value: '2-4m'
    },
    {
      text: '6+ months',
      value: '6m+'
    }
  ]
  const buttonsStore = [
    {
      text: 'Bella Vista',
      value: 'Bella Vista'
    },
    {
      text: 'Hornsby',
      value: 'Hornsby'
    },
    {
      text: 'Macquarie Park',
      value: 'Macquarie Park'
    },
    {
      text: 'Online',
      value: 'Online'
    }
  ]

  toastConfig({ theme: 'dark-edge' });

  const registerUser = (event) => {
    setAttemptSubmit(true)
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false || !formAge || !formGender || !formDevice || !formDiagnosis || !formStore) {
      event.stopPropagation();
    } else {
      setLoading(true);
      const formData = new FormData(event.currentTarget);
      const payloadBody = Object.fromEntries(formData);
      payloadBody['SentActivationReminder'] = false;
      payloadBody.age = formAge;
      payloadBody.gender = formGender;
      payloadBody.device = formDevice;
      payloadBody.diagnosis = formDiagnosis;
      payloadBody.store = formStore;
      payloadBody.address = formStore !== 'Online' ? "" : payloadBody.address;

      fetch('https://i8rekz3e78.execute-api.ap-southeast-1.amazonaws.com/v1/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_AWS_CONFIG_ACCESS_KEY_ID
        },
        body: JSON.stringify(payloadBody)
      })
        .then(response => response.json())
        .then(data => {
          if (data.statusCode === 200) {
            payloadBody['firstName'] && setName(payloadBody['firstName']);
            setLoading(false);
            handleShow();
          } else {
            throw new Error(data.body);
          }
        })
        .catch(async (error) => {
          console.error(error);
          let toastMessage = 'Something went wrong';

          if (error.response) {
            try {
              const errorData = await error.response.json();
              toastMessage = errorData.message
            } catch (e) {
              toastMessage = error.response.statusText
            }
          } else if (error.message) {
            toastMessage = error.message
          }

          toast(toastMessage, {
            duration: 5000,
            clickable: true,
            clickClosable: true
          });

          setLoading(false);
        });
    }

    setValidated(true);
  };

  return (
    <Form className="formSignUp" noValidate validated={validated} onSubmit={registerUser}>
      <Form.Group controlId="formFirstName">
        <Form.Label>First name</Form.Label>
        <Form.Control name="firstName" type="text" required />
      </Form.Group>
      <Form.Group controlId="formLastName">
        <Form.Label>Last name</Form.Label>
        <Form.Control name="lastName" type="text" required />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control name="email" type="email" required />
      </Form.Group>
      <div className='form-label mt-3'>Age</div>
      <Form.Group controlId="formAge">
        {buttonsAge.map((b, index) =>
          <Button className={`
            ${styles.radioButton} 
            ${formAge === b.value ? (attemptSubmit ? styles.radioButtonSelectedSuccess : styles.radioButtonSelected) : ''} 
            ${attemptSubmit ? (formAge ? styles.radioButtonSuccess : styles.radioButtonError) : ''}`} key={index} onClick={() => setFormAge(b.value)}>
            {b.text}
          </Button>
        )}
      </Form.Group>
      <div className='form-label mt-3'>Gender</div>
      <Form.Group controlId="formGender">
        {buttonsGender.map((b, index) =>
          <Button className={`
            ${styles.radioButton} 
            ${formGender === b.value ? (attemptSubmit ? styles.radioButtonSelectedSuccess : styles.radioButtonSelected) : ''} 
            ${attemptSubmit ? (formGender ? styles.radioButtonSuccess : styles.radioButtonError) : ''}`} key={index} onClick={() => setFormGender(b.value)}>
            {b.text}
          </Button>
        )}
      </Form.Group>
      <div className='form-label mt-3'>Device type</div>
      <Form.Group controlId="formDevice">
        {buttonsDevice.map((b, index) =>
          <Button className={`
            ${styles.radioButton} 
            ${formDevice === b.value ? (attemptSubmit ? styles.radioButtonSelectedSuccess : styles.radioButtonSelected) : ''} 
            ${attemptSubmit ? (formDevice ? styles.radioButtonSuccess : styles.radioButtonError) : ''}`} key={index} onClick={() => setFormDevice(b.value)}>
            {b.text}
          </Button>
        )}
      </Form.Group>
      <div className='form-label mt-3'>When did you receive your diagnosis?</div>
      <Form.Group controlId="formDiagnosis">
        {buttonsDiagnosis.map((b, index) =>
          <Button className={`
            ${styles.radioButton} 
            ${formDiagnosis === b.value ? (attemptSubmit ? styles.radioButtonSelectedSuccess : styles.radioButtonSelected) : ''} 
            ${attemptSubmit ? (formDiagnosis ? styles.radioButtonSuccess : styles.radioButtonError) : ''}`} key={index} onClick={() => setFormDiagnosis(b.value)}>
            {b.text}
          </Button>
        )}
      </Form.Group>
      <div className='form-label mt-3'>Store location</div>
      <Form.Group controlId="formStore">
        {buttonsStore.map((b, index) =>
          <Button className={`
            ${styles.radioButton} 
            ${formStore === b.value ? (attemptSubmit ? styles.radioButtonSelectedSuccess : styles.radioButtonSelected) : ''} 
            ${attemptSubmit ? (formStore ? styles.radioButtonSuccess : styles.radioButtonError) : ''}`} key={index} onClick={() => setFormStore(b.value)}>
            {b.text}
          </Button>
        )}
      </Form.Group>
      <Form.Group controlId="formAddress" style={{ marginTop: '15px', marginBottom: '30px', display: formStore === 'Online' ? 'block' : 'none' }}>
        <Form.Label>Home Address (Location the O2 ring will be delivered to)</Form.Label>
        <Form.Control name="address" type="text" required={formStore === 'Online' ? true : false} />
      </Form.Group>
      <Form.Group className={styles.checkform} controlId="formGridCheckbox1">
        <Form.Check name="consent" type="checkbox" required label={
          <span>
            Yes, sign me up! By checking this box, I consent to participate in the program. I understand that if I change my mind, I can opt out at any time.
          </span>}
        />
      </Form.Group>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form.Group className={`${styles.checkform} ${styles.short}`} controlId="formGridCheckbox2">
          <Form.Check name="offers" type="checkbox" label={
            <span>
              Please send me ResMed special offers and newsworthy updates. I can easily unsubscribe at any time.
            </span>}
          />
        </Form.Group>
        <div className={styles.expandIcon}>
          {expandCheck
            ? <span className="signUpIcon" onClick={() => setExpandCheck(!expandCheck)}><svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg></span>
            : <span className="signUpIcon" onClick={() => setExpandCheck(!expandCheck)}><svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg></span>
          }
        </div>
      </div>
      {
        expandCheck &&
        <div className={styles.expandedText}>
          ResMed will use your information in accordance with our Privacy Policy. ResMed will aim to send relevant offers and updates which may be based on ResMed's
          understanding of my circumstances and interests. As a result of your consent, ResMed group companies may contact you with promotional communications via email
          and text messages. To be able to tailor the communications to your preferences and behaviour and provide you with a personalised experience, we may analyse and
          combine your personal data based on data you give us and data we get from your interactions and usage of our digital channels, such as social media, websites,
          emails, apps and connected products. You can withdraw your consent at any time. For more information, please read our
          <Link className={styles.policyLink} to="/privacy-policy" target="_blank">Privacy Policy</Link>
        </div>
      }
      <Button className={styles.buttonSubmit} type="submit" disabled={loading}>
        {!loading ?
          <div className={styles.buttonSubmitText}>Get Started<span className="material-symbols-outlined">arrow_forward</span></div> :
          <Spinner as="span" size="sm" animation="border" role="status" />
        }
      </Button>
    </Form>
  )
}
