import Image from 'react-bootstrap/esm/Image';
import CallToAction from '../callToAction/callToAction';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import styles from './styles.module.css';

export default function SupportImages({ clickEvent }) {
  const callToActionText = [
    "What to learn more? Contact us today",
    "If you want to learn more about the Wellness Hub app send us a message and we’ll get back to you as soon as possible."
  ];
  const images = [
    "display1.png",
    "display2.png",
    "display3.png"
  ]

  return (
    <div className={styles.supportImagesWrapper}>
      <CallToAction clickEvent={clickEvent} text={callToActionText} />
      <Container className={styles.supportImagesContainer}>
        <Row className={styles.supportImagesRow}>
          {images.map((image, index) =>
            <Col key={index} className={styles.supportImagesCol}>
              <Image className={styles.supportImage} src={`images/${image}`} fluid />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}
