import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';
import './modal.css';

function ModalSignUpSuccess({ show, name, handleClose }) {
  return (
    <Modal className={styles.modal}
      centered
      show={show}
      onHide={handleClose}>
      <Modal.Header className={styles.modalHeader}><span className="material-symbols-outlined" onClick={handleClose}>
        close
      </span>
      </Modal.Header>
      <Modal.Body>
        <Container className={styles.modalContainer}>
          <Row className={styles.modalRow}>
            <Col className={styles.modalColumnLeft} sm={12} md={6}>
              <Image className={styles.signUpImage} src="images/signUpSuccess.png" fluid />
            </Col>
            <Col className={styles.modalColumnRight} sm={12} md={6}>
              <h1>You're all signed up!</h1>
              <p>Welcome{name ? ` ${name}.` : '.'} Thank you for registering your interest in participating in the Wellness Hub by ResMed trial.</p>
              <p>Keep an eye on your inbox for your temporary password and set up instructions to arrive!</p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalSignUpSuccess;