import Article from '../../components/article/article';
import styles from './styles.module.css';

export default function TermsOfUse() {
  const title = ["ResMed terms and conditions for the Wellness Hub App",
    "Effective Date: March 12, 2024"
  ]

  return (
    <div className="App">
      <Article title={title}>
        <div>Wellness Hub App Terms of Use</div>
        <div>Welcome to the Wellness Hub App. By using the Wellness Hub App, you agree to abide by and be subject to:</div>
        <ul>
          <li>These Terms of Use;</li>
          <li>Our Privacy Notice;</li>
        </ul>
        <div>If you do not agree with any of these Terms of Use, do not use the Wellness Hub App.</div>
        <div>&nbsp;</div>
        <div>1. Scope</div>
        <div>In these Terms of Use, the terms "you" refers to the person using the Wellness Hub. The terms "we," "our," "us" and "ResMed" collectively refer to ResMed Asia Pacific Limited and ResMed Pty Ltd, Australian corporations headquartered in New South Wales their parent ResMed Inc., a Delaware corporation, headquartered in California, and ResMed Asia Pacific Limited's and ResMed Pty Ltd's affiliates, organised under the laws of various states and countries and operating in California, the United States or in other jurisdictions.</div>
        <div>The servers that host Wellness Hub App are located in the Singapore, and any personal details you provide us will be processed by ResMed in the Singapore.</div>
        <div>These Terms of Use give you information about us and the legal terms and conditions on which we operate the Wellness Hub App.</div>
        <div>ResMed makes no representation about any third-party websites that may be accessed via links from this Website.</div>
        <div>If you choose to participate, you will be provided with a free oximeter wearable (valued AUD $199) (the “Complimentary Product”). You will be required to use the Complimentary Product for a period of up to 3 months (“Pilot Period”) in conjunction with the use of our products, as directed by your healthcare professional.</div>
        <div>You will be asked to engage in a range of market research activities as directed by us, including [Feedback on app features, rating your experience using the app, and its usefulness].</div>
        <div>The Complimentary Product will be owned by us during the Pilot Period. After the Pilot Period, ownership will transfer to you free of charge. No other payment or benefit will be given to you.</div>
        <div>The Complimentary Product is for General Wellness use only and is not intended for medical use.</div>
        <div>&nbsp;</div>
        <div>2. Privacy</div>
        <div>For information about the way in which we use, process and share personal and non-personal information that we may obtain about you, please refer to our <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Notice</a>.</div>
        <div>&nbsp;</div>
        <div>3. Disclaimer on Emergency Medical Conditions</div>
        <div>Do not use this App for medical emergency services. In an emergency, call your local emergency assistance number.</div>
        <div>&nbsp;</div>
        <div>4. Medical Disclaimers</div>
        <div>Content includes all text, images, data, information and other material displayed, available or present on this App. The Content available through this App is for informational and educational purposes only and is not a substitute for the professional judgment of a health care professional in diagnosing and treating patients. ResMed does not give medical advice, nor does it provide medical or diagnostic services. Additionally, the use of the Content obtained through this App does not establish a physician-patient relationship. Your reliance on information and Content obtained by you at or through this App is solely at your own risk. Neither we nor providers of Content to us assume any liability or responsibility for damage or injury (including death) to you, other persons or property arising from any use of any product, information, idea or instruction contained in the Content.</div>
        <div>We do not provide advice to you on any issues relating to medical treatment and the Content is intended solely as a resource and informational tool. Always seek the advice of a physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have seen or read on this App. We are not medical professionals and will not discuss or advise you on any issues relating to medical treatment. All data received by ResMed via this App is de-identified, meaning we cannot attribute your results to you. We have no liability or duty of care to take action or notify you if any of your results require further medical advice or investigation.</div>
        <div>&nbsp;</div>
        <div>5. Intellectual Property Rights</div>
        <div>Ownership</div>
        <div>All content, text, images, data, information and other material displayed, available or present on this App (Content), including any intellectual property rights in such Content (including without limitation trademarks and copyrights) (referred to as Intellectual Property Rights), are the property of ResMed, its affiliates, its licensors or the designated owners, and are protected by applicable intellectual property laws. You should assume that everything you see or read on this App is copyrighted unless otherwise noted and may not be used without our written permission, except as otherwise provided in these Terms of Use.</div>
        <div>Authorized and Prohibited Uses</div>
        <div>You may download and print copies of the Content only for non-commercial, informational, personal use, without modification or alteration in any way, and only so long as you comply with these Terms of Use and applicable laws. Except as may otherwise be expressly authorised by these Terms of Use, you may not otherwise reproduce, sell, publish, distribute, modify, display, or use any of this App or the Content without our prior written permission. If this App permits the emailing of certain Content or a link through the use of an "email to a friend" (or similar) icon, you may send that particular Content or link to others by email, as indicated. You agree not to infringe on any Intellectual Property Rights or remove or modify related proprietary notices contained in this App or the Content.</div>
        <div>&nbsp;</div>
        <div>6. User Content</div>
        <ol>
          <li>Except as provided in our App Privacy Policy, any Content that you submit to this App or ResMed, whether directly or through a third-party website (User Content) will be deemed to be non-confidential and may be disclosed through this App to unknown persons on a worldwide basis without control by ResMed, including for browsing, downloading, printing and other uses by such other persons or entities.</li>
          <li>You agree not to submit User Content to this App or ResMed except for User Content that is fully authorised for purposes of this App and these Terms of Use, and by posting User Content on this App, you warrant and represent that you own the rights to the User Content or are otherwise authorized to post, distribute, display, perform, transmit or otherwise distribute User Content. It is your obligation to determine the extent to which User Content you submit is protected by applicable intellectual property laws.</li>
          <li>You agree that ResMed will have, and you grant to ResMed, a worldwide, royalty-free, perpetual, irrevocable, sub-licenseable, non-exclusive right and license to translate, reproduce, sell, publish, distribute, modify, adapt, display, perform, promote, link to or use, in any form or media, any User Content that you submit to this App or ResMed.</li>
          <li>ResMed does not endorse any User Content, or third-party product or service that may appear on this App. Nothing in these Terms of Use will obligate ResMed to use any User Content you submit or permit the posting of such User Content on this App.</li>
        </ol>
        <div>&nbsp;</div>
        <div>7. Registration and Passwords</div>
        <ol>
          <li>At times, we may require you to have a password and provide registration details to access this App or portions of this App. To the extent that we do require a password and registration details, the details you provide must be correct, current and complete. If ResMed believes that the details are not correct, current and complete, we have the right to refuse you access to the App, or any of its resources, and to terminate or suspend your account, if any.</li>
          <li>You are responsible for maintaining the confidentiality of any password(s) you are given to access this App, and you are fully responsible for all activities that occur under your password(s). ResMed is not  liable for any loss that you incur as a result of someone else using your password, either with or without your knowledge. You may be held liable for any losses incurred by ResMed, its affiliates, officers, directors, employees, consultants, agents and representatives due to someone else's use of your account or password. You agree to notify us immediately of any unauthorized use of your password(s). We reserve the absolute right not to issue a password to any person or entity.</li>
        </ol>
        <div>&nbsp;</div>
        <div>8. App Administration</div>
        <div>Access Arrangements and Fees</div>
        <div>Your use of this App must be in accordance with any and all procedures, forms, formats, displays and operating times, which may be determined, specified or modified by ResMed in its discretion. You are responsible for all software, hardware, interconnections, fees, expenses, costs and taxes for you to access or use this App.</div>
        <div>At this time, we do not charge a fee to use this App.</div>
        <div>Linking</div>
        <div>Unless otherwise agreed between you and ResMed, no link shall be made to any page of this App except a direct link to the top page (eg, <a href="https://www.resmed.com.au/" target="_blank" rel="noreferrer">https://www.ResMed.com.au</a>) without framing. In addition, any link to this App must be immediately followed by notice to ResMed via email at <u>privacy@resmed.com</u>.</div>
        <div>Moreover, if ResMed deems your linking practices in relation to this App to be inappropriate, ResMed may choose to delete the link or to provide you with notice concerning removal or modification of the inappropriate link, and you agree to comply with any and all requirements of ResMed relating to such notice.</div>
        <div>Access to App</div>
        <div>While it is ResMed's objective to make the App accessible 24 hours per day, 7 days per week, the App may be unavailable from time to time for any reason including, without limitation, routine maintenance. You understand and acknowledge that due to circumstances both within and outside of the control of ResMed, access to the App may be interrupted, suspended or terminated from time to time. ResMed shall have the right at any time to change or discontinue any aspect or feature of the App, including, but not limited to, Content, hours of availability and equipment needed for access or use.</div>
        <div>Import/Export Control</div>
        <div>You agree not to submit any User Content to this App or commit any act involving the transfer of information relating to any Content in violation of applicable import/export control, espionage or national security laws.</div>
        <div>&nbsp;</div>
        <div>9. Your Obligations</div>
        <div>You agree to comply with the following:</div>
        <ul>
          <li>You will wear the Complimentary Product consistently throughout the Pilot Period in conjunction with the ResMed products and services that you already use.</li>
          <li>You will comply with all applicable laws in connection with your use of this App.</li>
          <li>You will not engage in, conduct or submit to this App any User Content or other material that is illegal, inaccurate, misleading, misappropriated, infringing, dilutive, defamatory, obscene, offensive or otherwise objectionable. You must not cause damage, embarrassment or adverse publicity to ResMed.</li>
          <li>You will submit only User Content that is functionally and technically compatible with this App. You will not attempt to and will not damage, corrupt, tamper with or infect this App, the Content or any information or telecommunication system of ResMed with a virus or other malicious computer program.</li>
          <li>You will only use this App for the permitted purposes stated in these Terms of Use, and will not engage in abusive activity with respect to this App, or undertake any other activity which may adversely affect the use or enjoyment of this App by any person. You are prohibited from using any services or facilities provided in connection with this App to compromise security or tamper with system resources and accounts.</li>
          <li>The use or distribution of tools designed for compromising security (eg, password guessing programs, cracking tools or network probing tools) is strictly prohibited. If you become involved in any violation of system security, ResMed reserves the right to release your details to system administrators at other sites and law enforcement authorities in order to assist them in resolving security incidents.</li>
          <li>You agree to cooperate with all reasonable requests of ResMed and will notify ResMed promptly on learning of any actual or suspected breach of these Terms of Use by you or unauthorised use or abuse of this App.</li>
        </ul>
        <div>&nbsp;</div>
        <div>10. Warranty Disclaimers</div>
        <div>This App, its Content and its links are provided on an "as is" basis and are used only at your sole risk, to the fullest extent permissible by law. ResMed disclaims all warranties, representations and conditions, express or implied, of any kind, regarding this App (including its Content, hardware, software and links), including any implied warranties as to fitness for a particular purpose, merchantability, title, non-infringement, results, accuracy, completeness, accessibility, compatibility, security and freedom from computer virus. If applicable law does not allow the exclusion of some or all of the above implied warranties to apply to you, the above exclusions will apply to you to the fullest extent permitted by applicable law.</div>
        <div>&nbsp;</div>
        <div>11. Limitations on Liability and Remedies</div>
        <div>ResMed's entire liability and your exclusive remedy with respect to any dispute with ResMed (including without limitation your use of the App) is to discontinue your use of the App.</div>
        <div>ResMed and its vendors shall not be liable for any direct, indirect, special, incidental, consequential or exemplary damage arising from your use of this App or for any other claim related in any way to your use or registration with ResMed. These exclusions for direct, indirect, special, incidental, consequential or exemplary damages include, without limitation, damages for lost profits, lost data, loss of goodwill, work stoppage, computer failure or malfunction, or any other commercial damages or losses, even if ResMed had been advised of the possibility thereof and regardless of the legal or equitable theory upon which the claim is based. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, ResMed and its vendors' liability in such state or jurisdiction shall be limited to the extent permitted by law. ResMed does not endorse, warrant or guarantee any product or service offered through this App and will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services. In any event, if any of the above provisions in this section are not enforceable in an applicable jurisdiction, the maximum liability of ResMed will be limited to, in the sole discretion of ResMed, the (1) correction or deletion of any inaccurate Content or link.</div>
        <div>&nbsp;</div>
        <div>12. Indemnification</div>
        <div>You agree to defend, indemnify and hold harmless ResMed and its affiliates, officers, directors, employees and contractors from any demands, claims, damages, liabilities, expenses or harms, including attorney's fees, arising in connection with your use of this App, online conduct, breach of these Terms of Use, or dealings or transactions with other persons resulting from use of this App.</div>
        <div>&nbsp;</div>
        <div>13. Third-Party Sites and Other Information</div>
        <div>This App may contain as a convenience to you, Content, links and other information submitted by third parties over whom ResMed has no control or responsibility, as well as translations thereof which ResMed may arrange. ResMed has no obligation to monitor, control or restrict the use of this App, or third-party websites accessible via links on this App. These other sites are not under ResMed's control, and you acknowledge that (whether or not such sites are affiliated in any way with ResMed) ResMed is not responsible for the accuracy, copyright compliance, legality, decency or any other aspect of the Content of such sites. The inclusion of such a link does not imply endorsement of any site by ResMed or any association with its operators.</div>
        <div>&nbsp;</div>
        <div>14. Separate Terms and Conditions</div>
        <div>From time to time in connection with your use of or access to Content contained in certain areas of this App, it may be necessary for you to consent to policies or terms and conditions in addition to these Terms of Use. You should read carefully any such additional terms and conditions before making any use of such Content or areas of this App. Any such terms will not vary or replace these Terms of Use regarding any use of this App, unless otherwise expressly stated.</div>
        <div>&nbsp;</div>
        <div>15. Dispute Resolution; Applicable Law and Forum</div>
        <div>With respect to any and all disputes arising out of or in connection with this App or these Terms of Use (including without limitation the Privacy Policy), ResMed and you agree to negotiate in good faith and undertake reasonable efforts to cooperate with one another in order to achieve a mutually satisfactory resolution.</div>
        <div>Each order will be governed by and will be construed in accordance with the laws of New South Wales, Australia. Each party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts of the State of New South Wales and its appellate courts and waives any right to object to proceedings being brought in those courts for any reason.</div>
        <div>ResMed has no obligation to become involved in any dispute between a user and any other person. ResMed and you agree that all disputes arising under these Terms of Use shall be brought by you in your individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding, and shall be resolved by confidential binding arbitration as selected by ResMed in its sole discretion. The arbitrator's award will be binding and may be entered as a judgment in a court of competent jurisdiction. You agree that ResMed is entitled to obtain preliminary injunctive relief to the extent allowed by law to enforce any of the terms of these Terms of Use pending a final arbitral decision.</div>
        <div>&nbsp;</div>
        <div>16. Modification of Terms of Use</div>
        <div>These Terms of Use and the Privacy Policy constitute the complete agreement between you and ResMed with respect to their subject matter and supersede any prior agreement or communication. These Terms of Use are subject to change from time to time. ResMed will endeavour to post a notice of any changes to these Terms of Use for a period of 30 days following any such modifications.</div>
        <div>Therefore, you are advised to review these Terms of Use occasionally, or at least every 30 days. Your continued use of this App subsequent to ResMed's notice of modification of these Terms of Use will constitute your acceptance of the modified Terms of Use.</div>
        <div>If any term or provision of these Terms of Use will be found to be invalid, illegal or otherwise unenforceable, such finding shall not affect the other terms or provisions of these Terms of Use, or the whole of these Terms of Use, but such term or provision shall be deemed modified to the extent necessary to render such term or provision enforceable, and the rights and obligations of you and ResMed shall be construed and enforced accordingly, preserving to the fullest permissible extent the intent and agreements set forth in these Terms of Use. Your obligations pursuant to these Terms of Use will survive termination of any use by you of this App, any Content on this App, or these Terms of Use.</div>
        <div>&nbsp;</div>
        <div>17. General</div>
        <div>Force Majeure</div>
        <div>We will not be liable for any delay or failure to perform under these Terms of Use if such delay is due to any circumstance beyond our reasonable control.</div>
        <div>Entire agreement</div>
        <div>These Terms of Use form the entire agreement between you and us.</div>
        <ol>
          <li>Changes
            <div>We reserve the right at any time to modify these Terms of Use and to impose new or additional terms or conditions. Such modifications and additional terms and conditions will be effective immediately and will apply to future orders you make with us.</div>
          </li>
          <li>Termination by us
            <div>We reserve the right to refuse to supply the Products ordered by you, terminate our contract with you or terminate your account with us at our sole discretion and without incurring any liability to you.</div>
          </li>
          <li>Support
            <div>If you require support for this Product please email DLabs@resmed.com</div>
          </li>
          <li>Assignment
            <div>You may not assign, sub-license or otherwise transfer any of your rights under these Terms of Use whether in whole or in part without our prior written consent.</div>
          </li>
          <li>Void or unenforceable terms
            <div>If any provision of these terms in this Terms of Use is found by any court of competent jurisdiction to be invalid, the invalidity of that provision will not affect the validity of the remaining provisions which will continue to have full force and effect. Only the parties to these Terms of Use may seek to enforce them.</div>
          </li>
        </ol>
        <div>&nbsp;</div>
        <div>18. Contact Information</div>
        <div>If you have any questions regarding these Terms of Use or this App, please contact us by email at wellness-hub@resmed.com</div>
      </Article>
    </div>
  )
}
