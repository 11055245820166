import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/esm/Stack';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';

export default function article({ title, children }) {
  return (
    <div className={styles.articleWrapper}>
      <Container className={styles.headerContainer}>
        <Row className={styles.headerRow}>
          <Col className={styles.headerColumn}>
            <Image className={styles.headerImage} src="images/resmedLogoColour.png" fluid />
            {title.map((t, index) =>
              <div key={index} className={styles.headerTitle}>{t}</div>
            )}
          </Col>
        </Row>
      </Container>
      <Container className={styles.bodyContainer}>
        <Stack gap={2}>
          {children}
        </Stack>
      </Container>
    </div>
  )
}
