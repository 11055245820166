import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './styles.module.css';

function Step({ data }) {
  return (
    <Container className={styles.stepContainer}>
      <Row>
        <Col xs={2} md={3} lg={12}>
          <Row>
            <Col className={styles.stepSymbol} sm={12}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="9.5" fill="white" stroke="#66ADD9" strokeWidth="5" />
              </svg>
            </Col>
            <Col className={styles.stepNumber} sm={12}>
              <div>
                0{data.id}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={10} md={9} lg={12}>
          <Row>
            <Col className={styles.stepHeader} sm={12}>
              <div>
                {data.header}
              </div>
            </Col>
            <Col className={styles.stepBody} sm={12}>
              {data.body}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}


export default Step;