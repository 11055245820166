
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';

function SignUp({ signUpRef, text, children }) {

  return (
    <div className={styles.signUpWrapper}>
      <Container ref={signUpRef} className={styles.signUpContainer}>
        <Row className={styles.signUpRow}>
          <Col className={styles.signUpColumnLeft} md={12} lg={5}>
            <Image className={styles.signUpImage} src="images/signUp.jpg" fluid />
          </Col>
          <Col className={styles.signUpColumnRight} md={12} lg={7}>
            <div className={styles.signUpHeader}>
              {text.title}
            </div>
            <div className={styles.signUpSubHeader}>
              {text.text}
            </div>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SignUp

