import Button from 'react-bootstrap/Button';
import styles from './styles.module.css';

function ButtonStarted({ clickEvent }) {
  return (
    <Button className={styles.buttonStarted} onClick={clickEvent}>
      Get Started<span className="material-symbols-outlined">arrow_forward</span>
    </Button>
  );
}

export default ButtonStarted;