import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dictionary from './dictionary/dictionary';
import CallToAction from '../callToAction/callToAction';
import styles from './styles.module.css';

function Dictionaries({ clickEvent }) {
  const callToActionText = [
    "Embark on a journey of discovery with us!",
    "Explore, Learn, and Grow Together — Your invitation to join a community of curiosity and knowledge."
  ];
  const dictionaries = [
    {
      "id": 1,
      "image": "images/sp02.jpg",
      "header": "Sp02",
      "subheader": "Unlocking vital insights to help improve health",
      "body": "SpO2, or peripheral capillary oxygen saturation, is a key metric indicating the percentage of oxygen in the blood [1]. This vital measurement provides insights into respiratory efficiency and overall oxygen delivery to the body's tissues. Tracking Sp02 can provide insight into overall health. Regular monitoring can be a useful tool for assessing Wellness."
    },
    {
      "id": 2,
      "image": "images/sp02Sleep.jpg",
      "header": "Sp02 & Sleep",
      "subheader": "Oxygen levels during sleep",
      "body": "Oxygen levels during the night the connection between SpO2 and sleep can be key in understanding respiratory health during rest. Monitoring oxygen saturation levels during sleep offers information about breathing patterns, potential disruptions, and overall sleep quality. Individuals with sleep disorders or conditions affecting respiratory function can benefit from tracking SpO2 during sleep to optimize interventions and enhance overall sleep health [2]."
    },
    {
      "id": 3,
      "image": "images/sleepWellness.jpg",
      "header": "Sleep & Wellness",
      "subheader": "Building a foundation for a healthy life",
      "body": "Sleep plays a pivotal role in overall wellness, influencing physical health, cognitive function, and emotional well-being. Exploring the intricate relationship between sleep and wellness unveils the impact of consistent, quality sleep on immune function, mental health, and daily performance [3]. Recognizing the importance of establishing healthy sleep habits becomes crucial for fostering a resilient foundation for overall well-being."
    },
    {
      "id": 4,
      "image": "images/sp02Cpap.jpg",
      "header": "Sp02 & CPAP",
      "subheader": "Synergizing respiratory health",
      "body": "CPAP therapy is a common intervention for sleep-related breathing disorders, and SpO2 can be a tool for monitoring its effectiveness in treating OSA. [4] CPAP devices work by providing a continuous flow of air to maintain open airways, positively impacting oxygen saturation levels during sleep. Understanding the relationship between SpO2 and CPAP can be beneficial for individuals utilizing this therapy, ensuring optimal respiratory support and improved overall health outcomes."
    }
  ]

  return (
    <div className={styles.dictionariesWrapper}>
      <CallToAction clickEvent={clickEvent} text={callToActionText} />
      <Container className={styles.dictionaryContainer}>
        <Row className={styles.dictionaryHeaderRow}>
          <Col className={styles.dictionaryHeaderCol}>
            Your sleep health dictionary
          </Col>
        </Row>
        <Row className={styles.dictionaryBodyRow}>
          {dictionaries.map((d) =>
            <Col key={d.id} className={styles.dictionaryBodyCol} md={12} lg={6} xl={3}>
              <Dictionary data={d}/>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default Dictionaries;