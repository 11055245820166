import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';

function Dictionary({ data }) {
  return (
    <Stack className={styles.dictionaryStack}>
      <Image className={styles.dictionaryImage} src={data.image} fluid />
      <div className={styles.dictionaryHeader}>{data.header}</div>
      <div className={styles.dictionarySubheader}>{data.subheader}</div>
      <div className={styles.dictionaryBody}>{data.body}</div>
    </Stack>
  )
}

export default Dictionary;