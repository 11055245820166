import Header from '../../components/header/header';
import SupportImages from '../../components/supportImages/supportImages';
import ButtonStarted from '../../components/buttonStarted/buttonStarted';
import Questions from '../../components/questions/questions';
import Footer from '../../components/footer/footer';
import SignUp from '../../components/signUp/signUp';

export default function Support() {
  const content = {
    headerText: {
      title: "Unlock total wellness through sleep",
      text: "The Wellness Hub app by ResMed, download now and begin shaping the future of health technology!",
    },
    questionsText: {
      title: "Some questions you might want to ask us right now",
      text: "Commonly asked question about the Wellness hub app. Lean on our experts for the answers.",
      link: "Stay connected and discover.",
      questions: [
        {
          "id": 0,
          "header": "How do I connect my wearable device to Wellness Hub?",
          "body": <div>
            <div>To connect your wearable device to Wellness Hub, follow these steps:</div>
            <ul>
              <li>Open the Wellness Hub app on your device.</li>
              <li>Navigate to the settings menu and select "Connect Device."</li>
              <li>Choose your specific wearable device from the list provided.</li>
              <li>Follow the on-screen instructions to pair your wearable device with the app.</li>
              <li>Once connected, your sleep and wellness data will automatically sync to the app.</li>
            </ul>
          </div>
        },
        {
          "id": 1,
          "header": "How can I log my daily sleep and wellness data?",
          "body": <div>
            <div>Logging your daily sleep and wellness data is simple:</div>
            <ul>
              <li>Open the Wellness Hub app.</li>
              <li>On the home screen, you will be prompted to reflect on your sleep, wellness, and sleep aid usage.</li>
              <li>Fill in your reflection.</li>
              <li>Save your entry to track your sleep and wellness over time.</li>
            </ul>
          </div>
        },
        {
          "id": 2,
          "header": "What should I do if my wearable device data isn't syncing?",
          "body": <div>
            <div>If your wearable device data isn't syncing, try these troubleshooting steps:</div>
            <ul>
              <li>Ensure that your device is properly connected to the Wellness Hub app.</li>
              <li>Check that device has enough battery to connect to the app.</li>
              <li>Restart both app and your phone.</li>
              <li>If the issue persists, please reach out to us at wellness-hub@resmed.com</li>
            </ul>
          </div>
        },
        {
          "id": 3,
          "header": "Is my data secure on the Wellness Hub app?",
          "body": "Yes, your data is secure on the Wellness Hub app. All data is handled in accordance to our Privacy Policy and Terms of Use. All data transmissions are de-identified, and we do not share your data with third parties without your explicit consent. Your privacy and security are our top priorities."
        },
        {
          "id": 4,
          "header": "How do I reset my password for the Wellness Hub app?",
          "body": <div>
            <div>If you need to reset your password:</div>
            <ul>
              <li>Open the Wellness Hub app.</li>
              <li>On the login screen, select "Forgot Password."</li>
              <li>Enter the email address associated with your account.</li>
              <li>Follow the instructions sent to your email to reset your password.</li>
              <li>If you do not receive an email, check your spam folder or contact us at wellness-hub@resmed.com</li>
            </ul>
          </div>
        }
      ]
    },
    formText: {
      title: "Begin your journey to learning more about you",
      text: "Sign-up now and claim your FREE Oximeter and Shape the Future of Health Tech by Embracing the Power of Restful Nights!"
    }
  }

  const mailTo = () => window.location.href = "mailto:wellness-hub@resmed.com"

  return (
    <div className="App">
      <Header text={content.headerText} />
      <SupportImages clickEvent={mailTo} />
      <Questions clickEvent={mailTo} questions={content.questionsText} />
      <SignUp text={content.formText}>
        <ButtonStarted clickEvent={mailTo} />
      </SignUp>
      <Footer />
    </div>
  );
}
