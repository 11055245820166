import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/esm/Stack';
import styles from './styles.module.css';


function Footer({ includeRefs }) {
  return (
    <div className={styles.footerWrapper}>
      <Container className={styles.footerContainerLogo}>
        <Row className={styles.footerRowLogo}>
          <Col className={styles.footerColumnLogo}>
            <Image className={styles.footerImageLogo} src="images/resmedLogoColour.png" fluid />
          </Col>
        </Row>
      </Container>
      {includeRefs &&
        <Container className={styles.footerContainerRefs}>
          <Row className={styles.footerRowRefs}>
            <Stack className={styles.footerStack} gap={5}>
              <div>[1] Hafen BB, Sharma S. Oxygen Saturation. [Updated 2022 Nov 23]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2024 Jan-. Available from: <a href='https://www.ncbi.nlm.nih.gov/books/NBK525974/' target="_blank" rel="noreferrer">https://www.ncbi.nlm.nih.gov/books/NBK525974/</a> (Accessed Jun 4, 2024)</div>
              <div>[2] Singh S, Khan SZ, Singh D, Verma S, Talwar A. The uses of overnight pulse oximetry. Lung India. 2020 Mar-Apr;37(2):151-157. doi: 10.4103/lungindia.lungindia_302_19. PMID: 32108601; PMCID: PMC7065557. Available from: <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7065557/' target="_blank" rel="noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7065557/</a> (Accessed Jun 4, 2024)</div>
              <div>[3] National Institutes of Health (NIH), SLEEP DEPRIVATION AND DEFICIENCY-How Sleep Affects Your Health [Updated 2022 Jun 15]. In: nhlbi.nih.gov [Internet]. Online Publishing; 2021 Oct-. Available from: <a href='https://www.nhlbi.nih.gov/health/sleep-deprivation/health-effects' target="_blank" rel="noreferrer">https://www.nhlbi.nih.gov/health/sleep-deprivation/health-effects</a> (Accessed Jun 4, 2024)</div>
              <div>[4] Nigro CA, Borsini EE, Dibur E, Larrateguy LD, Cazaux A, Elias C, de-la-Vega M, Berrozpe C, Maggi S, Grandval S, Cambursano H, Visentini D, Criniti J, Nogueira F. CPAP indication based on clinical data and oximetry for patients with suspicion of obstructive sleep apnea: A multicenter trial. Sleep Sci. 2019 Oct-Dec;12(4):249-256. doi: 10.5935/1984-0063.20190089. PMID: 32318245; PMCID: PMC7159076. Available from: <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7159076/' target="_blank" rel="noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7159076/</a> (Accessed Jun 4, 2024)</div>
            </Stack>
          </Row>
        </Container>
      }
    </div>
  );
}

export default Footer;