import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import styles from './styles.module.css';
import './accordion.css';

function AccordionCard({ data, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    data.id,
    () => callback && callback(data.id),
  );

  const isCurrentEventKey = activeEventKey === data.id;

  return (
    <Card>
      <Card.Header onClick={decoratedOnClick} className={isCurrentEventKey ? 'cardHeaderTopBorder' : 'cardHeaderFullBorder'}>
        <div className="cardHeaderIcon">
          {!isCurrentEventKey &&
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
              <g clipPath="url(#clip0_2508_191)">
                <path d="M16.5 16.5V7.5H19.5V16.5H28.5V19.5H19.5V28.5H16.5V19.5H7.5V16.5H16.5Z" fill="#B72755" />
              </g>
              <defs>
                <clipPath id="clip0_2508_191">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
          {isCurrentEventKey &&
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
              <g clipPath="url(#clip0_2508_84)">
                <path d="M7.5 16.5H28.5V19.5H7.5V16.5Z" fill="#424243" />
              </g>
              <defs>
                <clipPath id="clip0_2508_84">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        </div>

        <div className="cardHeaderText">{data.header}</div>
      </Card.Header>
      <Accordion.Collapse eventKey={data.id}>
        <Card.Body className="cardBodyText">
          {data.body}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

function Questions({ clickEvent, questions }) {

  return (
    <div className={styles.questionsWrapper}>
      <Container className={styles.questionsContainer}>
        <Row className={styles.questionsRow}>
          <Col className={styles.questionsColumnLeft} md={12} lg={4}>
            <Stack className={styles.questionsStack} gap={2}>
              <Image className={styles.questionsImage} src="images/questions.png" fluid />
              <div>{questions.title}</div>
              <div>{questions.text}</div>
              <div onClick={clickEvent}>{questions.link}</div>
            </Stack>
          </Col>
          <Col className={styles.questionsColumnRight} md={12} lg={8}>
            <Accordion defaultActiveKey={0}>
              {questions.questions.map((s) =>
                <AccordionCard key={s.id} data={s} />
              )}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Questions